import { createAvatar, parseUtc } from "~/shared/utils";
import { AppUser } from "~/types/AppUser";
import { paymentPlantoPaymentPlanIF } from "~/types/Payment";
import { Workspace, WorkspaceResponse } from "~/types/Workspace";

export const useWorkspace = () => {
  const getMappedWorkspaces = (
    workspaces: Array<WorkspaceResponse>,
  ): Array<Workspace> => {
    const mappedWorkspaces = workspaces.map((workspace) => {
      const displayName =
        typeof workspace.displayName === "string" &&
        workspace.displayName.length > 0
          ? workspace.displayName
          : `Workspace ${workspace.id}`;

      const displayImage =
        typeof workspace.logo === "string" && workspace.logo.length > 0
          ? workspace.logo
          : createAvatar(displayName, "initials");

      const parsedUtc = parseUtc(workspace.subscriptionEndDate);
      const subscriptionEndDate = new Date(
        parsedUtc.year,
        parsedUtc.month - 1,
        parsedUtc.day,
        parsedUtc.hour,
        parsedUtc.minute,
        parsedUtc.second,
      );

      return {
        ...workspace,
        displayName,
        displayImage,
        subscriptionEndDate,
        subscriptionExpired:
          new Date(subscriptionEndDate).getTime() < new Date().getTime(),
      };
    });

    return mappedWorkspaces;
  };

  const getActiveWorkspace = (appUserMe: AppUser | null) => {
    if (appUserMe) {
      const workspaceId = appUserMe.activeWorkspace;
      const { getWorkspaceById } = useWorkspaceStore();
      return getWorkspaceById(workspaceId);
    }
    return null;
  };

  const getPaymentPlanInfo = (workspace: Workspace | undefined | null) => {
    return workspace != null
      ? paymentPlantoPaymentPlanIF[workspace.plan]
      : null;
  };

  return {
    getMappedWorkspaces,
    getActiveWorkspace,
    getPaymentPlanInfo,
  };
};
