<template>
  <section class="bg-white">
    <div
      class="container flex items-center justify-center min-h-screen px-6 py-12 mx-auto"
    >
      <div class="w-full">
        <div class="flex flex-col items-center max-w-lg mx-auto text-center">
          <p class="text-sm font-medium text-primary-400">We're sorry</p>
          <h1 class="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
            {{ heading }}
          </h1>
          <p v-if="paragraph" class="mt-4 text-gray-500">
            {{ paragraph }}
          </p>

          <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
            <a
              href="/"
              class="rounded-md bg-primary-50 px-3.5 py-2.5 text-sm font-semibold text-primary-400 shadow-sm hover:bg-primary-100"
            >
              Refresh Page
            </a>
            <ButtonLogout />
            <a
              :href="contactUsLink"
              target="_blank"
              rel="noopener noreferrer"
              class="rounded-md bg-primary-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ISSUE_TYPEFORM } from "~/shared/constants";

const props = defineProps({
  error: {
    type: Object as PropType<
      Partial<{ statusCode: number; statusMessage: string; message: string }>
    >,
    required: true,
  },
});

const heading = computed(() => {
  if (props.error.statusMessage) {
    return props.error.statusMessage;
  }

  if (props.error.statusCode === 404) {
    return "Page not found";
  }

  return "An error occurred";
});

const paragraph = computed(() => {
  if (props.error.message) {
    return props.error.message;
  }

  if (props.error.statusCode === 404) {
    return "We searched high and low, but couldn’t find what you’re looking for.Let’s find a better place for you to go.";
  }

  return null;
});

const contactUsLink = ISSUE_TYPEFORM;
</script>
