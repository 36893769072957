import { useAppUserStore } from "~/stores/appUser";

export default defineNuxtPlugin((_) => {
  const { appUserMe } = useAppUserStore();
  if (!appUserMe) {
    return;
  }
  const { getAnalyticsUser, getAnalyticsWorkspace, identify, group } =
    useSegment();
  const { userId, traits: userTraits } = getAnalyticsUser(appUserMe);
  identify(userId, userTraits);
  const { getWorkspaceById } = useWorkspaceStore();
  const workspace = getWorkspaceById(appUserMe.activeWorkspace);
  if (!workspace) {
    return;
  }
  const { groupId, traits: workspaceTraits } = getAnalyticsWorkspace(
    appUserMe.id,
    workspace,
  );
  group(groupId, workspaceTraits);
});
