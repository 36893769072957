<template>
  <button :class="classStr" @click="signOutHandler">Logout</button>
</template>

<script setup lang="ts">
const { signOut } = useAuth();
const signOutHandler = async () => {
  await signOut({
    callbackUrl: "/auth/logout",
  });
};
const { classStr } = defineProps({
  classStr: {
    type: String,
    default:
      "rounded-md bg-primary-50 px-3.5 py-2.5 text-sm font-semibold text-primary-400 shadow-sm hover:bg-primary-100",
  },
});
</script>
