import flagsmith from "flagsmith";
import { getLocalFlagsmith } from "~/shared/flagsmith";

export default defineNuxtPlugin((nuxt) => {
  const {
    public: { isProduction },
  } = useRuntimeConfig();

  if (!isProduction) {
    // Enable all feature flags in non-production environments
    return {
      provide: {
        flagsmith: getLocalFlagsmith(),
      },
    };
  }

  flagsmith.setState(nuxt.payload.data.flagsmithState);

  return {
    provide: {
      flagsmith,
    },
  };
});
