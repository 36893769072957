import { defineStore } from "pinia";
import {
  AppUser,
  AppUserResponse,
  AppUserRole,
  AppUserStatus,
} from "~/types/AppUser";

export const useAppUserStore = defineStore({
  id: "app-user-store",
  state: () => {
    return {
      appUserMe: null,
      appUsers: [],
      appUsersOfClient: {},
    } as {
      appUserMe: AppUser | null;
      appUsers: Array<AppUser>;
      appUsersOfClient: Record<number, Array<AppUser>>;
    };
  },
  actions: {
    async getAppUserMe() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { user: AppUserResponse };
      }>("app_user/me");
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        showError({ statusMessage: errorMessage, fatal: true });
        return null;
      }
      if (data.value) {
        const { getMappedAppUsers } = useAppUser();
        this.appUserMe = getMappedAppUsers([data.value.data.user], null)[0];
      }
      return this.appUserMe;
    },

    async signUp(dto: {
      companyName: string;
      companyType: string;
      userName: string;
      email: string;
    }) {
      const { error } = await useDatAdsApiFetch("app_user/public/register/v2", {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async resendConfirmationMail(input: { email: string }) {
      if (input.email == null) {
        return;
      }
      const { error } = await useDatAdsApiFetch(
        "app_user/public/confirmation-mail",
        { method: "POST", body: { email: input.email } },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async sendPasswordResetMail(input: { email: string }) {
      if (input.email == null) {
        return null;
      }
      const { error } = await useDatAdsApiFetch(
        "app_user/public/password-reset-mail",
        { method: "POST", body: { email: input.email } },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async updateAppUserMe(
      input: Partial<{
        email: string;
        activeWorkspace: number;
        activeClientId: number | null;
        firstname: string;
        lastname: string;
        description: string;
        position: string;
        profileImage: File | null;
      }>,
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          user: AppUserResponse;
        };
      }>("app_user/me", {
        method: "PATCH",
        body: {
          ...input,
          profileImage: undefined,
          firstname: input.firstname === "" ? null : input.firstname,
          lastname: input.lastname === "" ? null : input.lastname,
          position: input.position === "" ? null : input.position,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        let profileImageUrl: string | null = null;
        if (input.profileImage) {
          const { errorMessage, url } = await this.uploadProfileImage({
            profileImage: input.profileImage,
          });
          if (errorMessage) {
            return errorMessage;
          }
          profileImageUrl = url;
        }
        const { getMappedAppUsers } = useAppUser();
        this.appUserMe = getMappedAppUsers(
          [
            {
              ...data.value.data.user,
              profileImage:
                typeof profileImageUrl === "string"
                  ? profileImageUrl
                  : data.value.data.user.profileImage,
            },
          ],
          null,
        )[0];
      }
      return null;
    },

    async uploadProfileImage(input: { profileImage: File }) {
      const formData = new FormData();
      formData.append("file", input.profileImage, input.profileImage.name);

      const { data, error } = await useDatAdsApiFetch<{
        data: { url: string };
      }>("app_user/profile-image", {
        method: "POST",
        body: formData,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return { errorMessage, url: null };
      }
      if (data.value) {
        return {
          url: data.value.data.url,
          errorMessage: null,
        };
      }
      return { errorMessage: null, url: null };
    },

    async listAppUsers(input?: { clientId?: number }) {
      let path = "app_user";
      if (typeof input?.clientId === "number") {
        path = `app_user/client/${input.clientId}`;
      }
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          users: Array<AppUserResponse>;
        };
      }>(path);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedAppUsers } = useAppUser();
        const appUserMeMaybe =
          this.appUserMe != null ? { ...this.appUserMe } : null;
        const users = getMappedAppUsers(data.value.data.users, appUserMeMaybe);
        if (typeof input?.clientId === "number") {
          this.appUsersOfClient[input.clientId] = users;
        } else {
          this.appUsers = users;
        }
        return users;
      }
      if (typeof input?.clientId === "number") {
        return this.appUsersOfClient[input.clientId] ?? [];
      }
      return this.appUsers;
    },

    async deleteAppUser(userId: number) {
      const { error } = await useDatAdsApiFetch(`app_user/${userId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.appUsers = this.appUsers.filter((user) => user.id !== userId);
      return null;
    },

    async createAppUsers(
      input: Array<{
        email: string;
        role: AppUserRole;
      }>,
    ) {
      if (input.length === 0) {
        return [];
      }
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          users: Array<AppUserResponse>;
        };
      }>("app_user/create", {
        method: "POST",
        body: [...input],
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedAppUsers } = useAppUser();
        const appUserMeMaybe =
          this.appUserMe != null ? { ...this.appUserMe } : null;
        const users = getMappedAppUsers(data.value.data.users, appUserMeMaybe);
        this.appUsers = [...this.appUsers, ...users];
        return users.map((user) => user.id);
      }
      return [];
    },

    async updateAppUsers(
      input: Array<{
        appUserId: number;
        role?: AppUserRole | undefined | null;
        status?: AppUserStatus | undefined | null;
        email?: string | undefined | null;
      }>,
    ) {
      if (input.length === 0) {
        return null;
      }
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          users: Array<AppUserResponse>;
        };
      }>("app_user", {
        method: "PATCH",
        body: [...input],
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedAppUsers } = useAppUser();
        const appUserMeMaybe =
          this.appUserMe != null ? { ...this.appUserMe } : null;
        const users = getMappedAppUsers(data.value.data.users, appUserMeMaybe);
        for (const user of users) {
          const userIdx = this.appUsers.findIndex((u) => u.id === user.id);
          if (userIdx !== -1) {
            this.appUsers[userIdx] = { ...user };
          } else {
            this.appUsers.push(user);
          }
        }
      }
      return null;
    },

    async assignClientsToAppUser(
      input: Array<{
        userId: number;
        clientId: number;
      }>,
    ) {
      if (input.length === 0) {
        return null;
      }
      const { error } = await useDatAdsApiFetch("app_user/client/assign", {
        method: "PATCH",
        body: [...input],
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async removeClientsFromAppUser(
      input: Array<{
        userId: number;
        clientId: number;
      }>,
    ) {
      if (input.length === 0) {
        return null;
      }
      const { error } = await useDatAdsApiFetch("app_user/client/remove", {
        method: "PATCH",
        body: [...input],
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },
  },
  getters: {
    getUserById: (state) => (id: number) => {
      return state.appUsers.find((appUser) => appUser.id === id);
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppUserStore, import.meta.hot));
}
