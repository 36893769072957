/* eslint-disable no-console */
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentryDsn, containerAppsEnvironment, release },
  } = useRuntimeConfig();
  const { getAnalyticsUser } = useSegment();
  const { appUserMe } = useAppUserStore();
  if (!appUserMe) {
    return;
  }
  const { userId, traits: userTraits } = getAnalyticsUser(appUserMe);

  if (!sentryDsn) {
    console.warn(
      `[sentry]: sentryDsn is empty - cannot initialise sentry plugin`,
    );
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDsn,
    environment: containerAppsEnvironment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: containerAppsEnvironment === "prod" ? 0.2 : 0,
    // Set sampling rate for profiling - this is relative to tracesSampleRate
    profilesSampleRate: containerAppsEnvironment === "prod" ? 0.2 : 0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "backend.datads.io",
      "qa.backend.datads.io",
      "backend2.datads.io",
      "qa.backend2.datads.io",
      "www.backend.datads.io",
      "www.qa.backend.datads.io",
      "www.backend2.datads.io",
      "www.qa.backend2.datads.io",
      "https://backend.datads.io",
      "https://qa.backend.datads.io",
      "https://backend2.datads.io",
      "https://qa.backend2.datads.io",
      "https://www.backend.datads.io",
      "https://www.qa.backend.datads.io",
      "https://www.backend2.datads.io",
      "https://www.qa.backend2.datads.io",
    ],
    replaysSessionSampleRate: containerAppsEnvironment === "prod" ? 0.1 : 0,
    replaysOnErrorSampleRate: containerAppsEnvironment === "prod" ? 1.0 : 0,
    release,
    initialScope: {
      user: {
        id: userId,
        ...userTraits,
      },
    },
  });
});
