export enum PaymentPlan {
  STARTER = "STARTER",
  PLUS = "PLUS",
  PRO = "PRO",
  CUSTOM = "CUSTOM",
}

export type InvoiceStatus =
  | "draft"
  | "open"
  | "void"
  | "paid"
  | "uncollectible";

export interface CouponIF {
  id: string;
  name: string;
  amountOff: number;
  percentOff: number;
  currency: string;
  duration: "once" | "repeating" | "forever";
  durationInMonths: number | null;
  valid: boolean;
}

export interface InvoiceIF {
  id: string;
  receiptUrl: string | null;
  amount: number;
  currency: string;
  status: InvoiceStatus | null;
  created: string;
}

export interface SubscriptionItemIF {
  id: string;
  priceId: string;
  amount: number;
  title: string;
  plan: PaymentPlan | "monthlyMembers";
  interval: "monthly" | "yearly";
  usage: number;
}

export interface SubscriptionIF {
  id: string;
  items: Array<SubscriptionItemIF>;
}

export interface CustomerAddressIF {
  city: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
}

export interface PaymentMethodIF {
  id: string;
  card: {
    brand: string;
    country: string | null;
    expMonth: number;
    expYear: number;
    last4: string;
  } | null;
  paypal: {
    email: string | null;
  } | null;
  sepaDebit: {
    bankCode: string | null;
    branchCode: string | null;
    country: string | null;
    last4: string | null;
  } | null;
}

export interface CustomerInfoIF {
  id: string;
  name: string | null;
  address: CustomerAddressIF | null;
  vatNumber: string | null;
  paymentMethod: PaymentMethodIF | null;
  deleted: boolean;
}

export interface PaymentPlanIF {
  id: PaymentPlan;
  displayName: string;
  monthlyPrice: {
    stripePriceId: string;
    testStripePriceId: string;
    displayValue: string;
    value: number;
  };
  yearlyPrice: {
    displayValue: string;
    value: number;
  };
  totalYearlyPrice: {
    stripePriceId: string;
    testStripePriceId: string;
    displayValue: string;
    value: number;
    level: number;
  };
  yearlyToMonthlyDiscount: {
    displayValue: string;
    value: number;
  };
  restrictions: {
    analyzedAds: number;
    members: number;
    workspaces: number;
    clients: number;
    lookbackMonths: number;
    dataRefreshHours: number;
  };
}

export const STARTER_PLAN: PaymentPlanIF = {
  id: PaymentPlan.STARTER,
  displayName: "Starter",
  monthlyPrice: {
    stripePriceId: "price_1NSIIlESqG48hppQrjPjGQqn",
    testStripePriceId: "price_1NUqHjESqG48hppQfrry9I2M",
    displayValue: "179€",
    value: 179.0,
  },
  yearlyPrice: {
    displayValue: "149€",
    value: 149.0,
  },
  totalYearlyPrice: {
    stripePriceId: "price_1NSIIlESqG48hppQO4CLpmOe",
    testStripePriceId: "price_1NUqHjESqG48hppQWb3Nci7W",
    displayValue: "1.788€",
    value: 1788.0,
    level: 1,
  },
  yearlyToMonthlyDiscount: {
    displayValue: "360€",
    value: 360.0,
  },
  restrictions: {
    analyzedAds: 500,
    members: 2,
    workspaces: 1,
    clients: 1,
    lookbackMonths: 12,
    dataRefreshHours: 24,
  },
};

export const PLUS_PLAN: PaymentPlanIF = {
  id: PaymentPlan.PLUS,
  displayName: "Plus",
  monthlyPrice: {
    stripePriceId: "price_1PpUrBESqG48hppQ55Eh6SkP",
    testStripePriceId: "price_1PpUxSESqG48hppQfTCoO7HA",
    displayValue: "359€",
    value: 359.0,
  },
  yearlyPrice: {
    displayValue: "299€",
    value: 299.0,
  },
  totalYearlyPrice: {
    stripePriceId: "price_1PpUrBESqG48hppQqTzpVwdD",
    testStripePriceId: "price_1PpUxSESqG48hppQXzaAxs6z",
    displayValue: "3.588€",
    value: 3588.0,
    level: 2,
  },
  yearlyToMonthlyDiscount: {
    displayValue: "720€",
    value: 720.0,
  },
  restrictions: {
    analyzedAds: 1000,
    members: 10,
    workspaces: 1,
    clients: 20,
    lookbackMonths: 12,
    dataRefreshHours: 12,
  },
};

export const PRO_PLAN: PaymentPlanIF = {
  id: PaymentPlan.PRO,
  displayName: "Pro",
  monthlyPrice: {
    stripePriceId: "price_1PpUp8ESqG48hppQn0pCg8aM",
    testStripePriceId: "price_1PpUwcESqG48hppQvhv0R8vr",
    displayValue: "719€",
    value: 719.0,
  },
  yearlyPrice: {
    displayValue: "599€",
    value: 599.0,
  },
  totalYearlyPrice: {
    stripePriceId: "price_1PpUpeESqG48hppQooe5aXFs",
    testStripePriceId: "price_1PpUwcESqG48hppQlTv9xTg8",
    displayValue: "7.188€",
    value: 7188.0,
    level: 3,
  },
  yearlyToMonthlyDiscount: {
    displayValue: "1.440€",
    value: 1440.0,
  },
  restrictions: {
    analyzedAds: 3000,
    members: 30,
    workspaces: Infinity,
    clients: Infinity,
    lookbackMonths: 24,
    dataRefreshHours: 6,
  },
};

export const CUSTOM_PLAN: PaymentPlanIF = {
  id: PaymentPlan.CUSTOM,
  displayName: "Custom",
  monthlyPrice: {
    stripePriceId: "price_1PpUmLESqG48hppQ487MMZim",
    testStripePriceId: "price_1PpUsnESqG48hppQ7qEgPIT9",
    displayValue: "1.439€",
    value: 1439.0,
  },
  yearlyPrice: {
    displayValue: "1.199€",
    value: 1199.0,
  },
  totalYearlyPrice: {
    stripePriceId: "price_1PpUmLESqG48hppQTLfdKBST",
    testStripePriceId: "price_1PpUsnESqG48hppQhdQwD8xm",
    displayValue: "14.388€",
    value: 14388,
    level: 4,
  },
  yearlyToMonthlyDiscount: {
    displayValue: "2.880€",
    value: 2880.0,
  },
  restrictions: {
    analyzedAds: Infinity,
    members: Infinity,
    workspaces: Infinity,
    clients: Infinity,
    lookbackMonths: 24,
    dataRefreshHours: 6,
  },
};

export const paymentPlantoPaymentPlanIF: {
  [plan in PaymentPlan]: PaymentPlanIF;
} = {
  [PaymentPlan.STARTER]: STARTER_PLAN,
  [PaymentPlan.PLUS]: PLUS_PLAN,
  [PaymentPlan.PRO]: PRO_PLAN,
  [PaymentPlan.CUSTOM]: CUSTOM_PLAN,
};

export type PaymentIntentStatus =
  | "requires_payment_method"
  | "requires_confirmation"
  | "requires_action"
  | "processing"
  | "requires_capture"
  | "canceled"
  | "succeeded";

export const MONTHLY_PRICE_IDS = [
  STARTER_PLAN.monthlyPrice.stripePriceId,
  PLUS_PLAN.monthlyPrice.stripePriceId,
  PRO_PLAN.monthlyPrice.stripePriceId,
  STARTER_PLAN.monthlyPrice.testStripePriceId,
  PLUS_PLAN.monthlyPrice.testStripePriceId,
  PRO_PLAN.monthlyPrice.testStripePriceId,
];

export const YEARLY_PRICE_IDS = [
  STARTER_PLAN.totalYearlyPrice.stripePriceId,
  PLUS_PLAN.totalYearlyPrice.stripePriceId,
  PRO_PLAN.totalYearlyPrice.stripePriceId,
  STARTER_PLAN.totalYearlyPrice.testStripePriceId,
  PLUS_PLAN.totalYearlyPrice.testStripePriceId,
  PRO_PLAN.totalYearlyPrice.testStripePriceId,
];
