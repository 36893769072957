<template>
  <div role="status" class="max-w-sm animate-pulse">
    <div
      v-if="showLine(1)"
      class="h-2.5 bg-gray-200 rounded-full w-48 mb-4"
    ></div>
    <div
      v-if="showLine(2)"
      class="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"
    ></div>
    <div v-if="showLine(3)" class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
    <div
      v-if="showLine(4)"
      class="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"
    ></div>
    <div
      v-if="showLine(5)"
      class="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"
    ></div>
    <div
      v-if="showLine(6)"
      class="h-2 bg-gray-200 rounded-full max-w-[360px]"
    ></div>
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  numLines: {
    type: Number as PropType<number | null>,
    required: false,
    default: null,
  },
});

const showLine = (num: number) => {
  return props.numLines ? num <= props.numLines : true;
};
</script>
