import { IFlagsmith } from "flagsmith/types";
import { RouteLocationNormalized } from "#vue-router";
import { FeatureFlag } from "~/types/shared";

export const useFeatureFlags = (flagsmith?: IFlagsmith<string, string>) => {
  const $flagsmith = flagsmith ?? useNuxtApp().$flagsmith;

  const RouteToFeatureFlagMap: Record<string, FeatureFlag> = {
    "/inspiration-board": "inspiration-board_2024-01-01",
  };

  const hasFeature = (feature: FeatureFlag) => {
    return $flagsmith.hasFeature(feature);
  };

  const getValue = <T>(feature: string) => {
    return $flagsmith.getValue<T>(feature);
  };

  const getRouteFeatureFlag = (currentRoute: RouteLocationNormalized) => {
    const routes = Object.keys(RouteToFeatureFlagMap);
    const protectedRoute = routes.find((route) =>
      currentRoute.path.includes(route),
    );
    if (!protectedRoute) {
      return null;
    }
    return RouteToFeatureFlagMap[protectedRoute];
  };

  const canAccessRoute = (route: RouteLocationNormalized) => {
    const routeFeatureFlag = getRouteFeatureFlag(route);
    if (!routeFeatureFlag) {
      // No feature flag required for this route
      return true;
    }
    return hasFeature(routeFeatureFlag);
  };

  return {
    hasFeature,
    getValue,
    canAccessRoute,
  };
};
