import { CustomSession } from "~/types/Auth";

export const useDatAdsApiFetch: typeof useFetch = (url, opts?) => {
  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig();
  useAuthState();
  return useFetch(url, {
    ...opts,
    baseURL: apiBaseUrl,
    onRequest: async ({ options }) => {
      const { data, getSession } = useAuth();
      if (data.value == null) {
        // eslint-disable-next-line no-console
        console.warn("Session not found");
        return;
      }
      let sessionWithToken = data.value as CustomSession;
      if (new Date(sessionWithToken.expires).getTime() < Date.now()) {
        // eslint-disable-next-line no-console
        console.warn(
          "[useDatAdsApiFetch] Access token has expired, trying to refresh it",
          sessionWithToken.expires,
        );
        sessionWithToken = await getSession();
      }
      if (sessionWithToken.access_token == null) {
        throw new Error("Access token not found");
      }
      const headers = (options.headers as Record<string, string>) || {};
      headers.Authorization = `Bearer ${sessionWithToken.access_token}`;
      options.headers = headers; // Re-assign, otherwise headers not set
    },
  });
};
