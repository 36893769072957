<template>
  <div class="h-screen">
    <div v-if="loading" class="absolute top-0 left-0 pl-72 w-full">
      <div class="relative bg-gray-50 z-50">
        <div class="py-10 px-4 sm:px-6 lg:px-8">
          <SkeletonText class="w-full" />
          <div
            class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3 2xl:grid-cols-4 mt-16"
          >
            <SkeletonCard v-for="i in 20" :key="i" class="col-span-1" />
          </div>
        </div>
      </div>
    </div>
    <ButtonIcon
      v-if="loading"
      tooltip="Stop loading & go back to the previous page"
      class="absolute top-5 right-5 z-50"
      @click="onStopLoading"
    >
      <XMarkIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
    </ButtonIcon>
    <NuxtLayout :is-loading-page="loading">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { provideUseId } from "@headlessui/vue";

// https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1924681165
provideUseId(() => useId().replace(/[-:]/, "_"));

const nuxtApp = useNuxtApp();
const loading = ref(false);
const router = useRouter();
const route = useRoute();
const lastRoute = ref<string>("");
const throttle = 500;
const intervalIdMaybe = ref<NodeJS.Timeout | null>(null);

nuxtApp.hook("page:start", () => {
  lastRoute.value = route.fullPath;
  onStartLoadingMaybe();
});

nuxtApp.hook("page:finish", () => {
  loading.value = false;
  if (intervalIdMaybe.value) {
    clearTimeout(intervalIdMaybe.value);
  }
});

const onStartLoadingMaybe = () => {
  intervalIdMaybe.value = setTimeout(() => {
    loading.value = true;
  }, throttle);
};
const onStopLoading = () => {
  router.push(lastRoute.value);
  loading.value = false;
};
</script>

<style>
.v-popper--theme-tooltip .v-popper__inner {
  @apply text-xs px-2 py-1;
}
</style>
